import React, { useState } from "react";
import { Empty } from "../layout/Empty";
import { Loading } from "../layout/Loading";
import { Title } from "../layout/Title";
import { format } from "date-fns";

const formatSafeDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "-" : format(date, "d. M. y");
};

export const Revision = (props) => {
    const building = props.building;
    const [data] = useState(building?.revision?.data);
    const [inserted] = useState(building?.revision?.inserted);
    const [loading] = useState(false);

    return (
        <>
            <div className=" p-5 lg:p-8 bg-gray-100 bg-opacity-90">
                <div className="col-span-2 space-y-4">
                    <Title bold={"Technické"} title={"přehledy"} />
                    {data && data.length > 0 ? (
                        <>
                            <p className="text-gray-600 text-sm">
                                Přehled revizí objektu ke dni{" "}
                                {format(new Date(inserted), "d. M. y")}
                            </p>
                            <section className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200 ">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                            >
                                                Druh
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider "
                                            >
                                                Název
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Poslední
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Příští
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data &&
                                            data.map((revision, revIdx) => (
                                                <tr
                                                    key={revIdx}
                                                    className={
                                                        revIdx % 2 === 0
                                                            ? "bg-white"
                                                            : "bg-gray-50"
                                                    }
                                                >
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                        {revision.type}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {revision.name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {formatSafeDate(
                                                            revision.start
                                                        )}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        {formatSafeDate(
                                                            revision.next
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </section>
                        </>
                    ) : (
                        <>
                            <Empty />
                        </>
                    )}
                </div>
            </div>

            <Loading loading={loading} />
        </>
    );
};
